import type {} from '@sharefiledev/sharefile-appshell';

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'billing-pilet';

export const billingPiletExtensionSlots = {
	viewReceipts: `${prefix}:${namespace}:${owner}:view-receipts-page` as const,
	cancelAccount: `${prefix}:${namespace}:${owner}:cancel-account-page` as const,
};
