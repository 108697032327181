import * as React from 'react';
import { Account, AccountFeatures, User, UserRole } from '@citrite/sf-api';
import { BillingType } from '@sharefile/ecomm-models/dist/orders';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { ApplicationContextProvider } from './ApplicationContextProvider';
import { AsyncConvertToPaid } from './ConvertToPaid';
import {
	AsyncCancelAccount,
	AsyncEditBillingInfo,
	AsyncManagePlan,
	AsyncTrialInformationBanner,
} from './EditBilling';
import { showManagePlan } from './managePlanCheck';
import { AsyncReceiptsPage } from './Receipts';
import {
	AdminBillingRoute,
	ConvertFreeTrialRoute,
	EditBillingInfoRoute,
	ManagePlanRoute,
	ReceiptsRoute,
} from './routes';
import { billingPiletExtensionSlots } from './sdk';
import { t } from './translate';

export function setup(piletApi: PiletApi) {
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const account = context.account;
	const user = context.user;
	const accountId = context.account.Id;
	const userId = context.user.Id;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const isFINRA = context.account.Preferences?.IsFINRA ?? false;
	const isVDR = (context.account.PlanTrack ?? '') === 'VDR';
	const trialBannerExtensionName = 'urn:sfextslot:billing:trialbanner';

	if (accountId && userId && showManagePlan(context)) {
		piletApi.registerPage(ManagePlanRoute, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncManagePlan
					accountId={accountId}
					userId={userId}
					isFreeTrial={isFreeTrial}
					account={account}
					datePreferences={context.user.Preferences?.DateFormat}
					timePreferences={context.user.Preferences?.TimeFormat}
					isVDR={isVDR}
					isFINRA={isFINRA}
				/>
			</ApplicationContextProvider>
		));

		if (showTrialBanner(context)) {
			const daysRemaining = 5;
			piletApi.registerExtension(trialBannerExtensionName, () => (
				<AsyncTrialInformationBanner trialDaysLeft={daysRemaining} />
			));
		}

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ManagePlanRoute,
			title: () => t('manage_plan.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (showCancelAccount(user, account)) {
		piletApi.registerExtension(billingPiletExtensionSlots.cancelAccount, () => {
			return (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncCancelAccount account={account}></AsyncCancelAccount>
				</ApplicationContextProvider>
			);
		});
	}

	if (showConvertToPaid()) {
		piletApi.registerPage(`${ConvertFreeTrialRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncConvertToPaid />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ConvertFreeTrialRoute,
			title: () => t('convert_to_paid.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (accountId && userId && showEditBillingInfo()) {
		piletApi.registerPage(`${EditBillingInfoRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncEditBillingInfo />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: EditBillingInfoRoute,
			title: () => t('edit_billing_information.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (showReceipts()) {
		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ReceiptsRoute,
			title: () => t('receipts.left_nav_button_text') ?? '...',
			weight: 200,
			parent: AdminBillingRoute,
		});
		piletApi.registerPage(`${ReceiptsRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncReceiptsPage />
			</ApplicationContextProvider>
		));
	}

	function showEditBillingInfo() {
		if (!user.Roles?.includes(UserRole.AdminBilling)) {
			return false;
		}

		if (showConvertLink()) {
			return false;
		}

		if (!showBillingInfo()) {
			return false;
		}

		if (!account.CanChangeBilling) {
			return false;
		}

		if (account.IsCancelled) {
			return false;
		}

		if (
			getDisableAdminBillingSectionPreference() === 'ShowReceipts' ||
			getDisableAdminBillingSectionPreference() === 'DisableAll'
		) {
			return false;
		}

		return true;
	}

	function showBillingInfo() {
		if (!isSFHybrisAccount(account.BillingType)) {
			return false;
		}

		return (
			account.Preferences?.ShowBillingInfo ||
			(account.Preferences?.HideBillingInfoExceptions || '').indexOf(userId!) !== -1
		);
	}

	function getDisableAdminBillingSectionPreference() {
		return account.Preferences?.DisableAdminBillingSectionInApp || '';
	}

	function showConvertLink() {
		return account.IsFreeTrial && account.ConversionType === 'manual';
	}

	function showConvertToPaid() {
		if (!account.CanConvertFreeTrial) {
			return false;
		}

		if (!showConvertLink()) {
			return false;
		}

		if (!user.Roles?.includes(UserRole.AdminBilling)) {
			return false;
		}

		if (!showBillingInfo()) {
			return false;
		}

		if (account.BillingType !== 'Hybris' && account.BillingType !== 'SFHybris') {
			return false;
		}

		return true;
	}

	function showReceipts() {
		if (!isSFHybrisAccount(account.BillingType)) {
			return false;
		}

		if (!user.Roles?.includes(UserRole.AdminViewReceipts)) {
			return false;
		}

		if (account.IsFreeTrial) {
			return false;
		}

		if (!showBillingInfo()) {
			return false;
		}

		if (account.AccountFeatures?.includes(AccountFeatures.IsPlanTrackFreemium)) {
			return false;
		}

		if (account.IsCTXSBilledSMBAccount) {
			return false;
		}

		if (getDisableAdminBillingSectionPreference() === 'DisableAll') {
			return false;
		}

		return true;
	}
}

export function showCancelAccount(u: User, acc: Account) {
	if (!u.Roles?.includes(UserRole.AdminAccountPolicies)) {
		return false;
	}
	if (!u.Roles?.includes(UserRole.AdminChangePlan)) {
		return false;
	}
	if (acc.IsCancelled ?? false) {
		return false;
	}
	if (acc.BillingType !== BillingType.SFHybris) {
		return false;
	}
	if (!(acc.CanCancel ?? true)) {
		return false;
	}
	if (acc.Preferences?.DisableAdminBillingSectionInApp === 'ShowReceipts') {
		return false;
	}
	if (acc.Preferences?.DisableAdminBillingSectionInApp === 'DisableAll') {
		return false;
	}

	return true;
}

function isSFHybrisAccount(billingType?: string) {
	return billingType === 'SFHybris';
}

export function showTrialBanner(context: PiletUserContext) {
	if (!(context.account.IsFreeTrial ?? false)) {
		return false;
	}
	if (!context.account.TrialInfo || !context.account.TrialInfo.EndDate) {
		return false;
	}
	return true;
}

// remove once stage deploy succeeds
